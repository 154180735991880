<template>
  <CRow>
    <CCol>
      <CCard>
        <CCardHeader>
          <b>Update Profile</b>
        </CCardHeader>
        <CCardBody>
          <CAlert
            :color="alertColor"
            closeButton
            :show.sync="showAlert"
          >
            {{ alertContent }}
          </CAlert>
          <CForm style="margin-top: 24px;">
            <CInput
              label="User Name"
              horizontal
              type="text"
              v-model="userName"
            />
            <!-- <CInput
              label="User ID"
              horizontal
              type="text"
              v-model="userId"
            /> -->
            <!-- <CInput
              label="App ID"
              horizontal
              type="text"
              v-model="appId"
            /> -->
            <div class="form-group form-row">
              <label class="col-sm-3">List ID</label>
              <div class="col-sm-9">
                <v-select
                  :options="availableApps"
                  v-model="appId"
                  @input="onAppIdsChanged"
                />
              </div>
            </div>
            <!-- <CInput
              label="Image"
              horizontal
              type="text"
              v-model="image"
            /> -->
            <CInput
              label="Phone Number"
              horizontal
              type="number"
              v-model="phoneNumber"
            />
            <br>
            <CCard>
              <CCardHeader>
                <b>Custom Fields</b>
                <div class="card-header-actions">
                <CButton
                  color="primary"
                  @click="addCustomField">
                  +
                </CButton>
              </div>
              </CCardHeader>
              <CCardBody>
                <div class="form-group form-row" v-for="fieldItem in customFields" :key="fieldItem.id">
                  <div class="col-sm-3">
                    <input type="text" class="form-control" placeholder="field_key" v-model="fieldItem.key"/>
                  </div>
                  <!-- <div class="col-sm-3">
                    <input type="text" class="form-control" placeholder="Type"/>
                  </div> -->
                  <div class="col-sm-8">
                    <input type="text" class="form-control" placeholder="Value" v-model="fieldItem.value"/>
                  </div>
                  <div class="col-sm-1">
                    <CButton
                      color="danger"
                      @click="removeCustomField(fieldItem)">
                      -
                    </CButton>
                  </div>
                </div>
              </CCardBody>
            </CCard>
            <br><hr>
            <div class="form-actions">
              <CButton
                color="primary"
                :disabled='submitted'
                @click="updateProfileClicked()">
                Update Profile
              </CButton>
            </div>
          </CForm>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import AxiosBase from '../../api/AxiosBase'
import AppApi from '../../api/AppApi'

export default {
  name: 'UpdateProfile',
  created() {
    Vue.component('v-select', vSelect);
    this.initProfile();
    this.getApps();
  },
  data () {
    return {
      alertColor: 'success',
      showAlert: false,
      alertContent: "",

      availableApps: [],
      // selectedApps: [],

      submitted: false,
      userName: "",
      appId: "",
      // image: "",
      phoneNumber: "",

      customFieldId: 0,
      customFields: [],
    }
  },
  methods: {
    getApps() {
      var _this = this;
      AppApi.getApps().then(result => _this.availableApps = result);
    },
    initProfile() {
      var _this = this;
      _this.submitted = true;

      AxiosBase.get("/profile/get", {
        params: {
          oid: _this.$route.params.id
        }
      })
      .catch(function(error) {
        console.log(error);

        _this.alertColor = "danger";
        _this.showAlert = true;
        _this.alertContent = "Get current profile failed. Check console for detail."
        _this.submitted = false;
      })
      .then(function(res) {
        if (res?.status === 200 && res?.data) {
          let resData = res?.data;
          _this.userName = resData.user_name;
          _this.appId = resData.app_id;
          // _this.image = resData.image;
          _this.phoneNumber = resData.phone_number;

          if (resData.extra) {
            for (let key in resData.extra) {
              _this.customFieldId = _this.customFieldId + 1;
              _this.customFields.push({
                id: _this.customFieldId,
                key: key,
                value: resData.extra[key]
              });
            }
          }
        } else {
          // show errors
          _this.alertColor = "danger";
          _this.showAlert = true;
          _this.alertContent = `Get current profile failed. Code: ${res?.status}`

          console.log("errors: " + JSON.stringify(res));
        }
        _this.submitted = false;
      });
    },
    updateProfile(formData) {
      var _this = this;
      _this.submitted = true;

      AxiosBase.post("/profile/update", formData)
      .catch(function(error) {
        console.log(error);

        _this.alertColor = "danger";
        _this.showAlert = true;
        _this.alertContent = "Update new profile failed. Check console for detail."
        _this.submitted = false;
      })
      .then(function(res) {
        var resCode = res?.data?.code;
        var retId = res?.data?.value;

        if (resCode != null && resCode >= 0) {
          _this.alertColor = "success";
          _this.showAlert = true;
          _this.alertContent = `Update profile successfully. ID: ${retId}`;
        } else {
          // show errors
          _this.alertColor = "danger";
          _this.showAlert = true;
          _this.alertContent = `Update profile failed. Code: ${resCode}. Reason: ${res?.data?.ext?.reason}`

          console.log("errors: " + JSON.stringify(res));
        }
        _this.submitted = false;
      });
    },
    updateProfileClicked() {
      let formData = {
        _id: this.$route.params.id,
        user_name: this.userName,
        app_id: this.appId,
        // image: this.image,
        phone_number: this.phoneNumber
      };

      let extra = {};
      for (let customField of this.customFields) {
        if (customField.key) {
          extra[customField.key] = customField.value;
        }
      }

      if (Object.keys(extra).length > 0) {
        formData.extra = extra;
      }

      this.updateProfile(formData);
    },
    onAppIdsChanged(value) {
      // console.log(this.selectedApps);
    },
    addCustomField() {
      this.customFieldId = this.customFieldId + 1;
      this.customFields.push({
        id: this.customFieldId,
        key: "",
        value: ""
      });
    },
    removeCustomField(fieldItem) {
      this.customFields = this.customFields.filter(function(item) {
        return item.id !== fieldItem.id;
      });
    }
  }
}
</script>
